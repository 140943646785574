$primary_black: #303331;
$primary_blue: #12a7bb;
$hover_blue: #1094a6;
$error_orange: #e47e13;
$color_white: #ffffff;
$color_black: #000000;
$color_gray: #dcdfe1;
$secondary_black: #727672;
$gray_50: #808080;
$gray_54: #8a8a8a;
$gray_60: #999;
$gray_72: #b8b8b8;
$gray_74: #bdbdbd;
$gray_87: #ddd;
$gray_93: #eeeeee;
$gray_98: #fafafa;
$blue_44: #1a7cc7;
$blue_45: #1979ca;
$blue_50: #0000ff;
$blue_82: #b7e4ea;
$cyan_85: #c4e9ee;
$green_69: #a9d18e;
$yellow_50: #ffc000;
$red_46: #e00c0c;
$color_disable: #00000061;
$color_label: #212529;
$color_activation: #fbe5d6;
