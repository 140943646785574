@media (min-width: 992px) {
    .push-right {
        .sidebar {
            left: 235px !important;
        }
        .main-container {
            margin-left: 235px !important;
            .log_action_fixed {
                left: 250px !important;
            }
            .log_content_fixed {
                left: 250px !important;
            }
        }
        .list-group {
            display: flex !important;
        }
        .toggle_icon_fwd {
            display: none !important;
        }
        .toggle_icon_back {
            display: inline !important;
        }
    }
}

@media (max-width: 992px) {
    .push-right {
        .sidebar {
            left: 235px !important;
        }
        .main-container {
            margin-left: 235px !important;
            .log_action_fixed {
                left: 250px !important;
            }
            .log_content_fixed {
                left: 250px !important;
            }
        }
        .list-group {
            display: flex !important;
        }
        .toggle_icon_fwd {
            display: none !important;
        }
        .toggle_icon_back {
            display: inline !important;
        }
    }
}