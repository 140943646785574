/* You can add global styles to this file, and also import other style files */
@import 'styles/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body {
    font-family: 'Roboto', "Helvetica Neue", sans-serif;
}

// body {
//     margin: 0;
//     font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//     line-height: 1.5;
// }
.p_2 {
    padding: 2px !important;
}

.p_5 {
    padding: 5px !important;
}

.p_15 {
    padding: 15px;
}

.pt_5 {
    padding-top: 5px;
}

.pt_8 {
    padding-top: 8px;
}

.pt_20 {
    padding-top: 20px;
}

.pl_5 {
    padding-left: 5px
}

.pl_6 {
    padding-left: 6px;
}

.pl_25 {
    padding-left: 25px !important;
}

.pl_50 {
    padding-left: 50px !important;
}

.pt_10 {
    padding-top: 10px;
}

.pt_12 {
    padding-top: 12px;
}

.pt_25 {
    padding-top: 25px;
}

.pb_12 {
    padding-bottom: 12px;
}

.pb_10 {
    padding-bottom: 10px;
}

.pb_25 {
    padding-bottom: 25px;
}

.pb_3 {
    padding-bottom: 3px;
}

.pb_5 {
    padding-bottom: 5px;
}

.pl_10 {
    padding-left: 10px;
}

.pl_12 {
    padding-left: 12px;
}

.pl_20 {
    padding-left: 20px;
}

.pl_28 {
    padding-left: 28px;
}

.pl_32 {
    padding-left: 32px;
}

.pl_60 {
    padding-left: 60px;
}

.pl_70 {
    padding-left: 70px;
}

.m_12 {
    margin: 12px;
}

.mtb_8 {
    margin: 8px 0 8px 0;
}

.mtb_15 {
    margin: 15px 0;
}

.mt_1{
    margin-top: 1px
}
.mt_5 {
    margin-top: 5px
}

.mt_8 {
    margin-top: 8px;
}

.mt_10 {
    margin-top: 10px;
}

.mt_12 {
    margin-top: 12px !important;
}

.mt_20 {
    margin-top: 20px !important;
}
.mt_22 {
    margin-top: 22px !important;
}

.mt_24 {
    margin-top: 24px;
}

.mt_25 {
    margin-top: 25px;
}

.mt_30 {
    margin-top: 30px;
}

.mt_36 {
    margin-top: 36px;
}
.mt_196{
    margin-top: 196px;
}

.mt_-6 {
    margin-top: -6px;
}

.mb_0 {
    margin-bottom: 0;
}

.mb_1 {
    margin-bottom: 1px;
}

.mb_2 {
    margin-bottom: 2px;
}
.mb_5 {
    margin-bottom: 5px;
}

.mb_6 {
    margin-bottom: 6px;
}

.mb_8 {
    margin-bottom: 8px;
}

.mb_point5 {
    margin-bottom: 0.5rem;
}

.mb_10 {
    margin-bottom: 10px;
}

.mb_16 {
    margin-bottom: 16px;
}

.mb_25 {
    margin-bottom: 25px;
}

.mb_30 {
    margin-bottom: 30px;
}

.mb_-4 {
    margin-bottom: -4px !important;
}

.mb_20 {
    margin-bottom: 20px;
}

.ml_5 {
    margin-left: 5px;
}

.ml_8 {
    margin-left: 8px;
}

.ml_10 {
    margin-left: 10px;
}

.ml_12 {
    margin-left: 12px;
}

.ml_24 {
    margin-left: 24px;
}

.ml_45 {
    margin-left: 45px;
}

.ml_120 {
    margin-left: 120px !important;
}

.ml_neg6 {
    margin-left: -6px !important;
}

.ml_neg4 {
    margin-left: -4px;
}

.ml_-25 {
    margin-left: -25px;
}

.ml_55 {
    margin-left: 55px;
}

.mr_5 {
    margin-right: 5px !important;
}

.mr_8 {
    margin-right: 8px !important;
}

.mr_10 {
    margin-right: 10px !important;
}

.mr_12 {
    margin-right: 12px !important;
}

.mr_20 {
    margin-right: 20px !important;
}

.mr_24 {
    margin-right: 24px !important;
}

.mt_-20 {
    margin-top: -20px !important;
}
.ml_neg2 {
    margin-left: -2px;
}

.text_right {
    text-align: right;
}

.text_left {
    text-align: left;
}

.text_center {
    text-align: center;
}

.text_start {
    text-align: start;
}

.font_bold {
    font-weight: bold;
}

.font_bold_500 {
    font-weight: 500 !important; //important tag is given so that it can override default style(affected area export violation logs)
}

.align_right {
    float: right;
}

.align_left {
    float: left;
}

.font_14 {
    font-size: 14px;
}

.font_15 {
    font-size: 15px !important;
}

.font_16 {
    font-size: 16px !important;
}

.font_18 {
    font-size: 18px;
}

.font_bold_600 {
    font-weight: 600;

}

.mat_card_border {
    border: 1px solid $color_gray;
}

.lightbox_header {
    width: 100%;
    height: 30px;
}

table {
    width: 100%;
    border: 1px solid $color_gray !important;
}

.sidebar_icon_size svg {
    height: 24px;
    display: block;
}

.no_results {
    text-align: center;
    padding: 15px 0;
}

.no_result_hide {
    display: none !important;
}

mat-table {
    mat-header-row {
        background-color: $secondary_black !important;

        mat-header-cell {
            background-color: $secondary_black !important;
            color: $color_white !important;
            font-size: 14px !important;
            margin-right: 12px;
            padding-right: 0;
            line-height: 18px;
            letter-spacing: normal !important;
        }

        mat-header-cell:last-child {
            margin-right: 24px;
            padding-right: 0;
        }
    }

    mat-header-row:nth-child(2) {
        background-color: $color_white !important;
    }

    mat-row {
        mat-cell {
            margin-right: 12px;
            padding-right: 0;
            letter-spacing: normal !important;
        }

        mat-cell:last-child {
            margin-right: 24px;
            padding-right: 0;
        }
    }
}

span.mat-checkbox-label {
    padding-left: 8px !important;
}

.min_width_70 {
    min-width: 70px;
}

.min_width_130 {
    min-width: 130px;
}

.width_75 {
    width: 75%;
}

.width_70 {
    width: 70%;
}

.width_20 {
    width: 20%;
}
.width_15 {
    width: 15%;
}
.width_25 {
    width: 25%;
}

.width_50 {
    width: 50%;
}

.width_80 {
    width: 80% !important;
}

.width_93 {
    width: 93%;
}

.width_100 {
    width: 100%;
}

.width_100px {
    width: 100px;
}

.width_400px {
    width: 400px;
}

.width_300px {
    width: 300px;
}

.accord_border {
    border: 1px solid $gray_74;
}

.tab_nav_link {
    background-color: $primary_blue;
    color: $color_white;
    padding: 5px;

    a {
        margin: 0 8px;
        cursor: pointer;
    }
}

// mat-checkbox.mat-checkbox-disabled.mat-checkbox-checked {
//     .mat-checkbox-inner-container .mat-checkbox-background {
//     background-color: $primary_blue !important;
//     }
// }

.mat-drawer-inner-container::-webkit-scrollbar {
    display: none;
}

.button-row {
    background-color: #dcdfe1 !important;
    color: #000 !important;
    opacity: 0.5 !important;
    font-size: 15px !important;
    border-radius: 22px !important;
    line-height: 36px !important;
    padding: 0 16px !important;
    border-radius: 4px !important;
}

.primary_btn {
    background-color: $primary_blue !important;
    color: $color_white !important;
    font-size: 15px;
    border-radius: 22px !important;
    outline: none !important;
    min-width: 88px !important;
    width: auto;

    &:hover {
        background-color: $hover_turquoise !important;
        color: $color_white;
        outline: none !important;
    }
}

.primary_btn[disabled="true"] {
    background-color: $color_gray !important;
    color: $color_black !important;
    opacity: 0.5 !important;
    font-size: 15px !important;
    border-radius: 22px !important;
    outline: none;
    letter-spacing: normal;

    &:hover {
        background-color: rgba(0, 0, 0, 0.12);
    }
}

.secondary_btn {
    background-color: #dcdfe1;
    color: #000;
    font-size: 15px;
    border-radius: 22px !important;
    outline: none !important;
    min-width: 88px !important;
    width: auto;
}

.secondary_btn:hover {
    background-color: #12a7bb;
    color: #fff;
    outline: none !important;
}

.primary_btn[ng-reflect-disabled="true"] {
    background-color: $color_gray !important;
    color: $color_black !important;
    opacity: 0.5 !important;
    font-size: 15px !important;
    border-radius: 22px !important;
    outline: none;
    letter-spacing: normal;
}

.primary_btn[ng-reflect-disabled="false"] {
    background-color: $primary_blue !important;
    color: $color_white !important;
    font-size: 15px !important;
    border-radius: 22px !important;
    outline: none;
    letter-spacing: normal;

    &:hover {
        background-color: $hover_turquoise !important;
    }
}

.secondary_btn[ng-reflect-disabled="true"] {
    opacity: 0.5 !important;
}

.secondary_btn[ng-reflect-disabled="false"],
.secondary_btn[ng-reflect-disabled="true"] {
    background-color: #dcdfe1 !important;
    color: #000 !important;
    font-size: 15px !important;
    border-radius: 22px !important;
    outline: none;
}

.secondary_btn[ng-reflect-disabled="false"]:hover {
    background-color: #12a7bb !important;
    color: #fff !important;
}

.general_btn {
    background-color: #1094a6;
    color: #fff;
    font-size: 15px;
    border-radius: 22px !important;
    outline: none !important;
    min-width: 88px !important;
    width: auto;
}

.mat-checkbox-checked .mat-accent .mat-checkbox-ripple .mat-ripple-element {
    opacity: 0.03 !important;
    background-color: #12a7bb !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #12a7bb !important;
}

.section-header {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color: black;
}

.sub-section {
    line-height: 30px !important;
    font-weight: 500;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #12a7bb !important;
}

.mat-expansion-panel-body {
    padding: 0 !important;
}

mat-expansion-panel-header {
    font-size: 15px !important;
    font-weight: 700 !important;
    height: 32px !important;
    background-color: #dbdfe0 !important;
    padding: 0px 8px !important;
}

.mat-expansion-panel-header-title {
    color: #313330 !important;
}

.mat-radio-button.mat-radio-checked .mat-radio-container .mat-radio-outer-circle {
    border-color: #12a7bb !important;
}

.mat-mdc-radio-button.mat-radio-checked .mat-radio-container .mat-radio-inner-circle {
    background-color: #12a7bb !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #12a7bb;
}

.mat-option.mat-active {
    color: #12a7bb !important;
}

.addIcon:hover {
    color: #12a7bb !important;
}

.hyperlink-btn-active {
    background-color: $primary_blue !important;
    color: $color_white !important;
    font-size: 15px !important;
    border: 1px solid $primary_blue !important;
    border-radius: 9px 9px 0 0 !important;
    white-space: break-spaces !important;
    text-align: center !important;
    outline: none !important;

    &:hover {
        background-color: $hover_turquoise !important;
    }
}

.hyperlink-btn {
    background-color: $color_white !important;
    color: $primary_blue !important;
    font-size: 15px !important;
    border: 1px solid $primary_blue !important;
    border-radius: 9px 9px 0 0 !important;
    white-space: break-spaces !important;
    text-align: center !important;
    outline: none !important;

    &:hover {
        background-color: $gray_93 !important;
    }
}

.mat-button-focus-overlay {
    background-color: transparent !important;
}

:host ::ng-deep .mat-mdc-error {
    color: #e47e13;
    font-size: 15px;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.mat-card-no-border {
    box-shadow: none !important;
    transition: none !important;
    padding: 0px !important;
    display: revert !important;
}

input {
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.error {
    color: #e47e13;
    font-size: 15px !important;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.error-message {
    color: #e47e13;
    font-size: 15px !important;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.page-header {
    font-size: 24px;
    font-weight: 500;
    float: left;
}

.page_header {
    font-size: 24px;
    font-weight: 500;
}

.window-header {
    font-size: 20px;
    font-weight: 500;
    float: left;
    line-height: 32px;
}

.window_header {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 10px;
}

.mat-expansion-panel-body th {
    line-height: 18px;
    background-color: #727672 !important;
    color: white !important;
    font-size: 14px !important;
}

.now-btn {
    color: $primary_blue;
}

.now-btn:hover {
    text-decoration: underline;
    cursor: pointer;
}

.now-btn-invalid {
    color: $gray_60;
}

.swal2-title {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 24px !important;
    font-weight: 500 !important;
}

.swal2-html-container {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.swal2-confirm {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px !important;
    font-weight: 500 !important;
}

// button:focus {
//     border: 0 !important;
//     outline-color: transparent !important;
//     outline: 0px !important;
// }

.mat-expansion-panel-header span.mat-expansion-indicator {
    display: block !important;
}

// .mat-checkbox-label{
//     margin-left:3px !important;
// }
.pageTitle {
    align-items: center;
}

.custom-checkbox-label label {
    margin: 0px !important;
}

.custom-checkbox-label label span {
    padding-left: 8px !important;
}

.cutsom-mat-select-padding {
    padding: 0px !important;
}

.custom-swal2-title {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 20px !important;
    font-weight: 500 !important;
}

.custom-swal2-html-container {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
}

.custom-swal2-confirm {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.custom-panel {
    border: 1px solid lightgrey;
}

.custom-panel .mat-expansion-panel-content {
    font-size: 16px !important;
    line-height: 24px !important;
}

.custom-mat-radio label {
    margin: 0px !important;
    font-weight: 500 !important;
}

.notification-bg {
    background-color: #303331;
}

.custom-icon {
    margin-right: 8px !important;
}

.custom-checkbox-label {
    font-size: 16px !important;
}

.example-margin {
    font-size: 16px !important;
}

.pointer {
    cursor: pointer;
}

table.mat-mdc-table {
    tr.mat-mdc-header-row {
        background-color: #727672 !important;

        th.mat-mdc-header-cell {
            color: white !important;
            font-size: 14px !important;
            margin-right: 15px;
            line-height: 18px;
            padding-right: 25px;
        }

        th.mat-header-cell:first-child {
            margin-right: 24px;
            padding-right: 24px;
        }

        th.mat-header-cell:last-child {
            margin-right: 24px;
            padding-right: 24px;
        }
    }

    tr.mat-row {
        td.mat-cell {
            margin-right: 20px;
            padding-right: 25px;
        }

        td.mat-cell:first-child {
            margin-right: 24px;
            padding-right: 24px;
        }

        td.mat-cell:last-child {
            margin-right: 24px;
            padding-right: 24px;
        }
    }
}

::ng-deep .mat-tooltip {
    word-break: break-word;
}

.mat-mdc-option {
    font-size: 14px !important;
    // max-width is given for mat-option width
    max-width: 284px;
    background-color: #ffffff !important; //#57505 used to resolve mat option gap issue 
}

//#57505 used to resolve mat option gap issue 
.mat-mdc-option:hover {
    background-color: #f5f5f5 !important;
}
//#57505 used to resolve mat option gap issue 
.mdc-list-item--selected.mat-mdc-option-active{
    background-color: #f5f5f5 !important;
}

.disableText {
    color: #ababab !important;
}

button:focus {
    outline: 0 !important;
}

mat-datepicker-toggle button:focus {
    outline: 3px solid $primary_blue !important;
}

.mat-calendar-controls button:focus {
    outline: 3px solid $primary_blue !important;
}

.mr-25 {
    margin-right: 25px;
}

::-ms-reveal {
    display: none;
}

.agent-Link {
    text-decoration: underline;
    cursor: pointer;
}

.disableMatIcon {
    pointer-events: none !important;
}

.menu_items_max_width.mat-mdc-menu-panel {
    max-width: initial !important
}

/* Email Alert Common Classs */
.email_alert_align {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.device_alert {
    min-height: 110px;
}

.security_alert {
    min-height: 70px;
}

.agent_alert,
.other_alert {
    min-height: 40px;
}

.custom_text_box div.mat-mdc-form-field-infix {
    padding-top: 24px !important;
    padding-bottom: 6px !important;

    input {
        font-size: 14px;
    }
}

.mat-mdc-icon-button:disabled {
    color: #00000040 !important;
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text,
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
    color: #12a7bb !important;
}

mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after {
    color: #12a7bb !important;
}

.mat-mdc-dialog-surface {
    padding: 20px;
}

.mat-mdc-card {
    display: block !important;
}

textarea {
    width: 100%;
}

.mat-mdc-select-value {
    font-size: 14px !important;
}

.cursorPointerEnable {
    cursor: pointer !important;
}

.cursorPointerDisable {
    pointer-events: none !important;
    opacity: 0.5;
}

.mdc-dialog__title::before {
    height: unset !important;
    display: unset !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    color: black !important;
}

.custom_mat_table_align .mdc-data-table__header-cell {
    padding-right: 10px !important;
    padding-left: 10px !important;
    line-height: 18px !important;
    -ms-word-break: break-all !important;
    word-break: keep-all !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
}

.custom_mat_table_align .mdc-data-table__cell {
    padding-right: 10px !important;
    padding-left: 10px !important;
    line-height: 18px !important;
    -ms-word-break: break-all !important;
    word-break: break-word !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
}


// to remove * for mat-lable default behaviour
.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
    margin-left: 1px;
    margin-right: 0px;
    content: unset !important;
}


// added to handle the button padding in dialog
.mdc-dialog__actions {
    padding: unset !important;
}

// to avoid word break in button
.mat-mdc-raised-button {
    white-space: nowrap;
}

//for only input feild font size
.mat-mdc-form-field-input-control {
    font-size: 14px !important;
}

//for date picker toggle icon adjustment
.mat-mdc-form-field-icon-suffix {
    padding: 4px !important;
    margin-top: 15px !important;
}

// to overwrite dropdown width as per content
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    min-width: fit-content;
}

// to display text-overflow ellipsis in dropdown
.mat-mdc-option.mdc-list-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

// For all simple-filter dropdown height adjustment
.combo_box {
    height: 31px !important;

    .mat-mdc-select-arrow svg {
        top: 0% !important;
    }
}

// Border for mat-card
.matCardBorder {
    border: 1px solid $gray_87 !important;
}

// letter spacing is removed for button
.mdc-button {
    letter-spacing: unset;
}

//for space reduction b/w radio options in analytics
.adjust_height_btw_radio {
    .mdc-form-field {
        height: 12px !important;
    }
}

// letter spacing is removed for div,span,para
div,
span,
p {
    letter-spacing: normal !important;
}

//for input text alignment
.input_data {
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
        padding-top: 26px !important;
        padding-bottom: 0px !important;
    }
}

// to hide the month name in calendar
.mat-calendar-body-label {
    visibility: hidden !important;
}

// to change the color of datepicker icon after active
.mat-datepicker-toggle-active {
    color: $primary_blue;
}

// added to change background color of perticular date once user hover on calendar
:hover .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: $hover_blue_calendar !important;
}

// added to change the border color when mat text field disabled
.mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: rgba(0, 0, 0, 0.6);
}

// added for buttons labels to avoid text wrapping
.mdc-button__label {
    white-space: nowrap !important;
}

// added to display text on next line with /n
.whitespace_preline {
    white-space: pre-line;
}

.whitespace_nowrap {
    white-space: nowrap;
}

.disableDiv {
    pointer-events: none;
    opacity: 0.5;
}

.h_50 {
    height: 50px
}

// security policy sub heading backgroud colour
.heading_bg {
    background-color: $color_backgroud;
}

//Icon size
.icon-size {
    width: 24px !important;
    height: 24px !important;
}

.display-flex {
    display: flex;
}

.display-block {
    display: block !important;
}

.h_60 {
    height: 60px;
}

.h_30 {
    height: 30px;
}

.boxShadow-none {
    box-shadow: none !important
}

.editPolicy .mat-expansion-panel-spacing {
    margin: 0;
}

//to unwrap mat-options in dropdown
.mat-mdc-option .mdc-list-item__primary-text {
    white-space: nowrap !important;
}

.title {
    width: 100%;
    height: 40px
}
// for mat-table while loading data
.h_10vh {
    min-height: 10vh;
}
// for datepicker input feild so that thay can shrink and expand based on resolution
.timetosend_fx_10 {
    flex: 1 1 100%;
    max-width: 10%;
}

//for charts so that thay can shrink and expand based on resolution
.chart_fx_50 {
    flex: 1 1 100%;
    max-width: 50%;
}

.chart_fx_60 {
    flex: 1 1 100%;
    max-width: 60%;
}

.chart_fx_40 {
    flex: 1 1 100%;
    max-width: 40%;
}

.h_6vh{
    min-height: 6vh;
}

// for mat-table while loading data
.h_9vh {
    min-height: 9vh;
}

// added a color for the Notes
.note_color {
    color: $error_orange !important;
}

// added for add and remove icon resize
.rmm_add_remove_icon svg {
    width: 32px !important;
    height: 32px !important;
}

.opacity_05 {
    opacity: 0.5;
}

.enableRemoveBtn svg path {
    fill: $primary_blue !important;
}

.list-padding {
    padding-inline-start: inherit !important;
    margin-bottom: 0px !important;
}

// mat arrow dropdown color change, when it is focused.
.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
    color: #303331 !important;
}

//text box inside the dropdown
.search-text-box {
    border: 1px solid $gray_72;
    padding-left: 5px;
    margin: 2px;
    width: 98%;
    text-overflow: ellipsis;

    &:focus {
        outline: 1px solid $primary_blue;
    }
}

.copy-wrapper {
    position: relative;
    display: inline-block;
    text-align: center;
}

.copy-message {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #495057;
    border: none;
    color: white;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    margin-bottom: 8px;
    white-space: nowrap;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.copy-image {
    width: 14px;
    height: 14px;
}