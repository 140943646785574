/* You can add global styles to this file, and also import other style files */
@import 'styles/colors.scss';

// Custom THEME for Synappx Cloud Print (SCP) app
@import "../../web/JACloud/libJACloud/projects/lib-ja-cloud/src/scp-theme.scss";

.app_bg {
    background-color:  $color_gray;
}

.cdk-global-scrollblock {
    overflow-y: scroll;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
}


div.mat-select-panel div.mat-select-content mat-option.mat-selected.mat-active span.mat-option-text {
    color: $primary_blue !important;
}
div.mat-select-panel div.mat-select-content mat-option span.mat-option-text {
    color: $primary_black !important;
}

.width_100 {
    width: 100%
}

.height_100 {
    height: 100%;
}
.mat-mdc-dialog-surface {
    padding: 20px !important;
}
.cdk-overlay-container {
    z-index: 10000;
}

.mat-accent .mat-input-element {
    caret-color: $primary_black !important;
}

.mat-form-field-invalid .mat-input-element,
.mat-warn .mat-input-element {
    caret-color: $primary_black !important;
}

.mat-input-element {
    caret-color: $primary_black !important;
}

:host ::ng-deep .mat-mdc-error {
    color: $error_orange !important;
    font-size: 12px !important;
}

.mat-mdc-option.mat-mdc-active {
    color: $primary_blue !important;
}

.mandatory {
    color: $error_orange;
    font-size: 15px;
    padding-left: 5px;
    white-space: nowrap;
}

.mdc-dialog__title::before {
    height: 0 !important;
}

p.mandatory {
    padding-left: 85px;
}

.text_right {
    text-align: right;
}

.text_left {
    text-align: left;
}

.text_center {
    text-align: center;
}

.align_right {
    float: right;
}

.th_note {
    color: $error_orange;
    padding: 0 10px 0 6px;
    font-size: 15px;
}

.font_10 {
    font-size: 10px !important;
}

.font_12 {
    font-size: 12px;
}

.font_13 {
    font-size: 13px !important;
}

.font_14 {
    font-size: 14px !important;
}

.font_15 {
    font-size: 15px !important;
}

.font_16 {
    font-size: 16px !important;
}

.font_18 {
    font-size: 18px;
}

.font_22 {
    font-size: 22px;
}

.font_bold {
    font-weight: bold;
}

.v_align_middle {
    vertical-align: middle;
}

.v_align_top {
    vertical-align: top;
}

.no_wrap {
    white-space: nowrap;
}

.margin_middle {
    margin: auto 0;
}

.link_name {
    cursor: pointer;
    color: inherit;
    text-decoration: underline !important;
    text-underline-position: under;
    &:hover {
        color: $primary_blue !important;
        text-decoration: underline !important;
    }
}

.gray_text_link {
    cursor: default;
    color: inherit;
    opacity: 0.5;
    text-decoration: none !important;
    pointer-events: none;
}

.pr_10 {
    padding-right: 10px;
}

.pr_12 {
    padding-right: 12px;
}
.pr_24 {
    padding-right: 24px;
}

.pr_30 {
    padding-right: 30px !important;
}

.pt_4 {
    padding-top: 4px;
}
.pt_6 {
    padding-top: 6px;
}

.pt_10 {
    padding-top: 10px !important;
}

.pt_12 {
    padding-top: 12px;
}

.pt_15 {
    padding-top: 15px;
}

.pt_24 {
    padding-top: 24px;
}

.pt_30 {
    padding-top: 30px;
}
.pl_10 { padding-left: 10px; }
.pl_15 {
    padding-left: 15px;
}
.pl_18 { padding-left: 18px; }
.pl_20 {
    padding-left: 20px;
}

.pl_35 {
    padding-left: 35px;
}

.pl_40 {
    padding-left: 40px;
}

.pb_5 {
    padding-bottom: 5px;
}
.pb_12 { padding-bottom: 12px; }
.pl_85{
    padding-left: 85px;
}

.mt_10 {
    margin-top: 10px;
}

.mt_15 {
    margin-top: 15px !important;
}

.mt_18 {
    margin-top: 18px;
}

.mt_20 {
    margin-top: 20px;
}

.mt_24 {
    margin-top: 24px;
}

.mt_25 {
    margin-top: 25px;
}

.mt_36 {
  margin-top: 36px;
}

.mt_50 {
    margin-top: 50px;
}

.mb_6 {
    margin-bottom: 6px;
}

.mb_10 {
    margin-bottom: 10px;
}

.mb_16 {
    margin-bottom: 16px;
}

.mb_26 {
    margin-bottom: 26px;
}

.ml_6 {
    margin-left: 6px;
}
.ml_10 {
    margin-left: 10px;
}
.ml_12 { margin-left: 12px !important; }
.ml_15 {
    margin-left: 15px;
}
.ml_20 {
    margin-left: 20px;
}

.ml_27 {
    margin-left: 27px;
}
.ml_48 {
    margin-left: 48px;
}
.mr_2{
    margin-right: 2px;
}
.mr_6 {
     margin-right: 6px; 
}
.mr_10 {
    margin-right: 10px !important;
}
.mr_24{
margin-right: 24px !important;
}
.mr_25 {
    margin-right: 25px !important;
}

.icon_ratio {
    width: 75%;
    height: 75%
}

.chart_ratio {
    width: 100%;
    height: auto;
}

.no-results {
    text-align: center;
    padding: 25px 0;
}

.display_inline {
    display: inline !important;
}

.display_flex {
    display: flex !important;
}

.align_item_center {
    align-items: center;
}

.mat-dialog-actions {
    margin-bottom: -8px;
}

.icon_search_align {
    position: absolute;
    margin: -4px 0 0 -4px;
}

input.input_summ_search {
    width:-webkit-fill-available;
}

.mat-calendar-table {
    border: 0 !important;
    .mat-calendar-body-selected {
        background-color: $primary_blue !important;
    }
}

/* =============== File Browse Control =============== */

.choose_file {
    padding: 12px 0 12px 0;
}

.file_browse {
    border: 1px solid $color_gray;
    padding: 3px 10px 0 3px;
    font-size: 14px;
    height: 36px;
    width: 550px;
    margin-right: 10px;
    outline: none;
    overflow: hidden;
    margin-bottom: 12px;
}

/* =============== For Edge Combobox full width =============== */

@supports (-ms-ime-align: auto) {
    mat-select.list_header_value .mat-select-value {
        width: 160px !important;
    }

    mat-select.display_list .mat-select-value {
        width: 160px !important;
    }

    mat-select.list_log_value .mat-select-value {
        width: 95% !important;
    }
}

/* =============== Table =============== */

mat-table {
    mat-header-row {
        background-color: $secondary_black !important;
        mat-header-cell {
            background-color: $secondary_black !important;
            color: $color_white !important;
            font-size: 14px !important;
            margin-right: 12px;
            padding-right: 0;
            line-height: 18px;
        }
        mat-header-cell:last-child {
            margin-right: 24px;
            padding-right: 0;
        }
    }
    mat-row {
        mat-cell {
            margin-right: 12px;
            padding-right: 0;
        }
        mat-cell:last-child {
            margin-right: 24px;
            padding-right: 24px !important;
        }
    }
}
.mdc-data-table__header-cell {
    padding: 0px 0px 0 10px !important;
}

.mat-cell {
    white-space: pre-wrap;
}

/* =============== Check Box =============== */
mat-checkbox label {
    margin: 15px 0 !important;
    .mat-checkbox-inner-container {
        width: 16px !important;
        height: 16px !important;
        margin-right: 0;
        div.mat-checkbox-frame {
            border-color: $secondary_black;
        }
        .mat-ripple-element {
            background: rgba(18, 167, 187, 0.4) !important;
        }
    }
}
mat-header-cell mat-checkbox {
    .mdc-checkbox__background {
        border-color: $color_white !important;
    }
}
mat-header-cell mat-checkbox.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
            border-color: transparent !important;
        }
}

mat-checkbox .mdc-form-field .mdc-checkbox {
    .mdc-checkbox__ripple {
        background: rgba(18, 167, 187, 0.4) !important;
    }
}

mat-header-cell,th mat-checkbox label .mat-checkbox-inner-container {
    .mat-checkbox-frame {
        border-color: $color_white !important;
    }
}

mat-checkbox.mat-checkbox-indeterminate {
    .mat-checkbox-inner-container .mat-checkbox-background {
        background-color: $primary_blue !important;
    }
}

mat-checkbox.mat-checkbox-checked {
    .mat-checkbox-inner-container .mat-checkbox-background {
        background-color: $primary_blue !important;
    }
}

mat-checkbox.mat-checkbox-disabled.mat-checkbox-checked {
    .mat-checkbox-inner-container .mat-checkbox-background {
    background-color: $color_gray !important;
    }
}

mat-checkbox:active {
    .mat-accent div.mat-ripple-element {
        background: $primary_blue !important;
        opacity: 0.4 !important;
    }
}
.mat-pseudo-checkbox {
    display:none !important;
}

/* =============== Radio Button =============== */

mat-radio-button label {
    margin: 12px 0 !important;
    .mat-radio-container {
        width: 20px !important;
        height: 20px !important;
        .mat-radio-outer-circle {
            border-color: rgba(0,0,0,.54) !important;
        }
        .mat-radio-inner-circle {
            background-color: $primary_blue !important;
        }
        .mat-ripple-element {
            background: rgba(18, 167, 187, 0.4) !important;
        }
    }
    .mat-radio-label-content {
        font-weight: 500;
    }
}

mat-radio-button.mat-radio-checked {
    .mat-radio-container {
        .mat-radio-outer-circle {
            border-color: $primary_blue !important;
        }
        .mat-radio-inner-circle {
            background-color: $primary_blue !important;
        }
        .mat-ripple-element {
            background: rgba(18, 167, 187, 0.4) !important;
        }
    }
}
mat-radio-button.mdc-radio {
    .mat-radio-ripple {
        background: rgba(18, 167, 187, 0.4) !important;
    }
}

.mat-radio-button.mat-radio-checked.mat-radio-disabled
    .mat-radio-container {
        .mat-radio-outer-circle {
            border-color: $color_gray !important;
    }
    .mat-radio-inner-circle {
        background-color: $color_gray !important;
    }
}
.mat-radio-button.mat-radio-disabled
    .mat-radio-container {
        .mat-radio-outer-circle {
            border-color: $color_gray !important;
    }
}

.search_label_color {
    color: $color_disable;
}

mat-radio-group.mat-mdc-radio-group.ng-valid {
    span.align-search {
        mat-label {
            color: $color_label !important;
        }
    }
}

/* =============== List Control and Text Box =============== */

.mat-sort-header-arrow {
    color: $color_white !important;
}

.mat-sort-header-container button {
    outline: none !important;
}

mat-form-field.mat-form-field-invalid div.mat-form-field-underline span.mat-form-field-ripple {
    background-color: $error_orange !important;
}

mat-form-field.mat-form-field-disabled {
    div.mat-form-field-wrapper {
        opacity: 0.5;
    }
}
mat-form-field div.mat-form-field-wrapper {
    padding: 6px 0 16px 0 !important;
    margin: 0 12px !important;
}

mat-form-field.mat-focused div.mat-form-field-infix input.mat-input-element {
    color: $primary_black !important;
    font-size: 14px !important;
}

mat-form-field div.mat-form-field-infix input {
    padding: 0.4em 0;
    font-size: 14px !important;
    line-height: 18px;
}

mat-form-field div.mat-form-field-infix mat-select {
    padding: 0.4em 0;
    font-size: 14px !important;
}

mat-form-field div.mat-form-field-infix span.mat-form-field-label-wrapper label.mat-form-field-label {
    color: $primary_black !important;
    font-size: 18px !important;
}

mat-form-field.mat-focused div.mat-form-field-infix span.mat-form-field-label-wrapper label.mat-form-field-label {
    color: $primary_black !important;
    font-size: 18px !important;
}

div.mat-form-field-underline {
    background-color: $primary_black !important;
}

mat-form-field.mat-focused div.mat-form-field-underline span.mat-form-field-ripple {
    background-color: $primary_blue !important;
}

mat-form-field div.mat-form-field-infix mat-select.mat-select div.mat-select-trigger {
    div.mat-mdc-select-arrow-wrapper div.mat-select-arrow {
        color: $primary_black !important;
    }

    div.mat-select-value {
        max-width: 100% !important;
    }
}

div.mat-paginator-range-actions {
    div.mat-paginator-range-label {
        color: $primary_black !important;
    }

    button.mat-icon-button {
        outline: none !important;
    }
}
 .mdc-text-field {
    padding: 0 !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple:before {
    border-bottom-color: $primary_black  !important;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple:before {
    border-bottom-color: orange !important;
}
.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple:after {
    border-bottom-color: $primary_blue  !important;
}

.mdc-text-field--filled .mdc-line-ripple:after {
    border-bottom-color: $primary_blue  !important;
}
.mdc-text-field--filled {
    background-color: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}

// .custom_text_box div.mat-mdc-form-field-infix {
//     padding-top: 24px !important;
//     padding-bottom: 6px !important;

//     input {
//         font-size: 14px;
//     }
// }

.mat-primary {

    .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after,
    .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
        color: #12a7bb;
    }
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
        color: #12a7bb;
    }
    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
        color: #303331 !important;
        font-size: 17px !important;
    }
    .mat-mdc-select-arrow {
        color: $primary_black;
    }
}
.mat-mdc-form-field.mat-accent .mdc-text-field__input {
    caret-color: #303331 !important;
}
.mat-mdc-form-field-error {
    color: #e47e13 !important;
    font-size: 11px !important;
}
.mdc-form-field>label {
    font-size: 1rem !important;
}
.mat_custom_header mat-form-field div.mat-mdc-form-field-infix mat-select.mat-mdc-select div.mat-mdc-select-trigger div.mat-mdc-select-arrow-wrapper div.mat-mdc-select-arrow {
    color: #fff !important;
}
.mat-mdc-form-field-error-wrapper{
    padding: 0px !important;
}
.mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
    color: #303331 !important;
    font-size: 18px !important;
}

/* =============== SVG Icons =============== */

mat-icon[svgicon="svg_import"] SVG, mat-icon[svgicon="svg_id_card"] SVG {
    padding: 3px;
}

/* =============== Custom Button =============== */

.service_btn {
    background-color: $primary_blue !important;
    color: $color_white !important;
    font-size: 15px;
    border-radius: 30px !important;
    outline: none !important;
    width: 170px !important;
    height: 48px !important;
    white-space: nowrap !important;
    line-height: 20px !important;
    &:hover {
        background-color: $hover_turquoise !important;
        color: $color_white !important;
        outline: none !important;
    }
}

.primary_btn {
    background-color: $primary_blue !important;
    color: $color_white !important;
    font-size: 15px !important;
    border-radius: 22px !important;
    outline: none !important;
    min-width: 88px !important;
    width: auto;
    letter-spacing: normal;
    &:hover {
        background-color: $hover_turquoise !important;
        color: $color_white;
        outline: none !important;
    }
}

.primary_btn[disabled='true'] {
    background-color: $color_gray !important;
    color: $primary_black !important;
    opacity: 0.5 !important;
    font-size: 15px !important;
    border-radius: 22px !important;
    outline: none;
    letter-spacing: normal;
    &:hover {
        background-color: rgba(0, 0, 0, 0.12);
    }
}

.secondary_btn {
    background-color: $color_gray !important;
    color: $primary_black !important;
    font-size: 15px !important;
    border-radius: 22px !important;
    outline: none !important;
    min-width: 88px !important;
    width: auto;
    &:hover {
        background-color: $primary_blue !important;
        color: $color_white !important;
        outline: none !important;
    }
}

/* Disable Button */
.primary_btn[ng-reflect-disabled='true'] {
    background-color: $color_gray !important;
    color: $primary_black !important;
    opacity: 0.5 !important;
    font-size: 15px !important;
    border-radius: 22px !important;
    outline: none;
    letter-spacing: normal;
}

.primary_btn[ng-reflect-disabled='false'] {
    background-color: $primary_blue !important;
    color: $color_white !important;
    font-size: 15px !important;
    border-radius: 22px !important;
    outline: none;
    letter-spacing: normal;
    &:hover {
        background-color: $hover_turquoise !important;
    }
}

.secondary_btn[ng-reflect-disabled='true'] {
    background-color: $color_gray !important;
    color: $primary_black !important;
    opacity: 0.5 !important;
    font-size: 15px !important;
    border-radius: 22px !important;
    outline: none;
}

.secondary_btn[ng-reflect-disabled='false'] {
    background-color: $color_gray !important;
    color: $color_black !important;
    font-size: 15px !important;
    border-radius: 22px !important;
    outline: none;
    &:hover {
        background-color: $primary_blue !important;
        color: $color_white !important;
    }
}

.primary_icon_btn {
    color: $primary_blue !important;
    span mat-icon {
        height: 32px !important;
        width: 32px !important;
        line-height: 32px !important;
    }
    &:hover {
        color: $hover_turquoise;
    }
}

.mat-slide-toggle.mat-primary.mat-checked {
    .mat-slide-toggle-bar {
        background-color: rgba(18, 167, 187, 0.5) !important;
        outline: none;
    }

    .mat-slide-toggle-thumb {
        background-color: $primary_blue !important;
        outline: none;
    }
}

/* =============== mat-icon-button =============== */

.icon_btn {
    color: $color_black !important;
    outline: none !important;
    place-content: center !important;

    &:hover {
        color: $primary_blue !important;
        outline: none !important;
        place-content: center !important;

        // added to change svg icon color
        svg path {
                fill: $primary_blue !important;
            }
    }

    .mat-button-focus-overlay {
        background-color: transparent;
    }
}

/* =============== Title & Footer Top Space =============== */

.import_msg {
    padding: 15px 0 5px 0;
    word-break: break-word;
    font-size: 15px;
    color: $error_orange;
    margin: 0 10px;
    width: 675px;
}

.warning {
    background: $error_orange;
}
.mat-error{
    font-size: 12px !important;
    color:$error_orange;
}

.wordwrap {
    white-space: pre-wrap;
}

.mat-expansion-panel-header span.mat-expansion-indicator {
    display: none !important;
}

.mat-expansion-panel-header-title {
    padding: 12px 0;
}

mat-expansion-panel-header {
    display: contents !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    height: 32px !important;
    background-color: #dbdfe0 !important;
    padding: 0px 8px !important;
}

.mat-expansion-panel-body {
    padding: 0 !important;
}

mat-paginator.disabled .mat-paginator-container button.mat-icon-button {
    pointer-events: none;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.26);
}

.icon_version_align {
    padding: 5px 0 0 2px;
    vertical-align: top !important;
}

button:focus {
    border: 1px;
    outline-color: $primary_blue;
}

a:focus {
    border: 1px;
    outline-color: $primary_blue;
}

.word_break {
    -ms-word-break: break-all;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.title_license_align {
    align-items: baseline !important;
    mat-card-title {
        padding-right: 12px;
    }
    mat-card-content {
        padding: 0 8px;
    }
}

/* Reports Pages Side Bar */
@media (min-width: 992px) {
    .log_action_fixed {
        left: 74px !important;
    }
    .log_content_fixed {
        left: 74px !important;
    }
}

@media screen and (max-width: 992px) {
    .log_action_fixed {
        left: 74px !important;
    }
    .log_content_fixed {
        left: 74px !important;
    }
}

mat-datepicker-content {
    display: table !important;
}
.mat-mdc-paginator-icon {
    display: inline-block;
    width: 28px !important;
    height: 28px !important;
}
/* to fix SHPLT-2246 */
.mat-mdc-paginator-container {
    padding-right: 30px !important;
}

/* to fix SHPLT-2299 */
@media (max-width: 992px) {
    .mat-mdc-paginator-container{
        padding-right: 60px !important;
     }
}

/* =============== Log Toggle Buttons =============== */

.btn_toggle_on {
    background-color: $primary_blue !important;
    color: $color_white !important;
    font-size: 16px !important;
    border-radius: 6px !important;
    border: 1px solid $primary_blue !important;
    font-weight: 500;
    padding: 28px 16px 28px 8px !important;
    box-shadow: none !important;
    letter-spacing: normal;
}

.btn_toggle_off {
    background-color: $color_white;
    color: $primary_blue !important;
    font-size: 16px !important;
    border-radius: 6px !important;
    border: 1px solid $primary_blue !important;
    font-weight: 500;
    padding: 28px 16px 28px 8px !important;
    box-shadow: none !important;
    letter-spacing: normal;
    &:focus {
        outline: none !important;
    }
}

.disable_select {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

:host ::ng-deep .mat-sort-header-container button {
    text-align: left;
}

:host ::ng-deep .mat-sort-header-content {
    text-align: left;
}

.analytics_picker {
    align-items: baseline;
    align-items: center;
    justify-content: space-between;
}

.linefeed-tooltip {
    white-space: pre-line;
}

/* ============== Devices and Agents ============ */

.highlight {
    background-color: $color_white !important;
    border-top: 2px solid $primary_blue !important;
    border-bottom: 2px solid $primary_blue !important;
}
.highlight_left {
    border-left: 2px solid $primary_blue !important;
}
.highlight_right {
    border-right: 2px solid $primary_blue !important;
}

input.input_width_search {
    width: 150px;
}

.pb_28 {
    padding-bottom: 28px;
}
.rmm-mailalert{
    z-index:10001 !important
}

.domains_refresh {
    height: 40px;
}

span.mat-placeholder-required {
    display: none;
}

.mat-calendar-abbr {
    text-decoration: none !important;
}

.mat-option.mat-active, .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled), .mat-option:hover:not(.mat-option-disabled) {
    background: rgba(0,0,0,.04) !important;
}
.mat-mdc-menu-item .mdc-list-item__primary-text {
    font-size: 14px !important;
}

.selected {
    background: #dedede !important;
}

.primary_icon_btn {
    color: $primary_blue !important;
    &:hover {
        color: $hover_turquoise;
    }
}

.accord_border {
    border: 1px solid $gray_74;
}

.p_5 {
    padding: 5px !important;
}
.mat-tooltip{
    font-size:14px !important;
}
.mdc-tooltip__surface{
font-size: 14px !important;
padding: 10px 6px!important;
max-width: 250px !important;
}

.mat-mdc-paginator-page-size-select {
    width: 70px !important;
}
.mat-mdc-paginator .mat-mdc-select {
    padding: 0 10px !important;
}
.mat-mdc-card-content {
    font-size: 14px;
   padding: 0 !important;
}

.mat-mdc-card {
    padding: 16px !important;
  //  min-width: 1200px;
}
mat-cell:first-of-type,mat-header-cell:first-of-type,mat-footer-cell:first-of-type {
    padding-left: 20px !important;
}

.mat-mdc-card-title {
    font-size: 24px !important;
    font-weight: 500;
}

.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-focus-icon-color: #12a7bb;
    --mdc-checkbox-selected-hover-icon-color: #12a7bb;
    --mdc-checkbox-selected-icon-color: #12a7bb;
    --mdc-checkbox-selected-pressed-icon-color: #12a7bb;
    --mdc-checkbox-unselected-focus-icon-color: grey;
    --mdc-checkbox-unselected-hover-icon-color: gray;
    // below css to remove pink color effect from ripple while clicking checkbox
    --mdc-checkbox-selected-focus-state-layer-color: #12a7bb;
    --mdc-checkbox-unselected-focus-state-layer-color: #12a7bb;
    --mdc-checkbox-selected-pressed-state-layer-color: #12a7bb;
    --mdc-checkbox-unselected-pressed-state-layer-color: #12a7bb;
}

.mat-mdc-icon-button.mat-mdc-button-base {
    width: 32px;
    height: 32px;
    padding: 0Px !important;
}

.mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #12a7bb;
    --mdc-radio-selected-hover-icon-color: #12a7bb;
    --mdc-radio-selected-icon-color: #12a7bb;
    --mdc-radio-selected-pressed-icon-color: #12a7bb;
    --mat-mdc-radio-checked-ripple-color: #12a7bb;
    --mat-radio-checked-ripple-color:#12a7bb;
}

.mat-icon {
    height: 32px;
    width: 32px;
}
button.mat-mdc-icon-button .mat-icon {
    width: 32px !important;
    height: 32px !important;
    line-height: 32px;
    border-radius:16px; //for Round background instead of Square background
}

// Ripple Background Stays after select - Removed
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    background-color: transparent !important;
}

.mat-mdc-slide-toggle.mat-primary {
    --mdc-switch-selected-focus-state-layer-color: #12a7bb;
    --mdc-switch-selected-handle-color: #12a7bb;
    --mdc-switch-selected-hover-state-layer-color: #12a7bb;
    --mdc-switch-selected-pressed-state-layer-color: #12a7bb;
    --mdc-switch-selected-focus-handle-color: #12a7bb;
    --mdc-switch-selected-hover-handle-color: #12a7bb;
    --mdc-switch-selected-pressed-handle-color: #12a7bb;
    --mdc-switch-selected-focus-track-color: #12a7bb;
    --mdc-switch-selected-hover-track-color: #12a7bb;
    --mdc-switch-selected-pressed-track-color: #12a7bb;
    --mdc-switch-selected-track-color: #12a7bb;
    --mdc-switch-unselected-track-color: #686767;
}
:host ::ng-deep mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
    display: none !important;
    fill:#12a7bb !important;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
    color: #12a7bb;
}

.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: $primary_black !important;
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: $primary_blue !important; 
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color:$light_blue !important;
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: $primary_black !important;
    font-size: 18px !important;
}

// .mdc-text-field .mdc-floating-label {
//     transform: translateY(-16%) !important
// }

.mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-106%) scale(0.75);
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 24px !important;
    padding-bottom: 8px !important;
}
:host ::ng-deep .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
    content: none !important;
}
.mat-mdc-option{
    line-height: 3em !important;
    min-height: 3em !important;
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
    margin: 0 !important;
    padding: 0 0px 0 16px !important;
}
.mat-form-field-hide-placeholder {
    .mdc-text-field .mat-mdc-form-field-infix .mdc-floating-label {
            transform: translateY(-6%) !important;
        }
}
.mdc-dialog .mdc-dialog__content{
    padding: 0px !important;
}
.mdc-dialog__title {
    padding: 0 0px 9px !important;
}
.mdc-text-field--invalid .mdc-text-field__input {
    caret-color: gray;
}
.mdc-checkbox__background {
    width: 16px !important;
    height: 16px !important;
    border: 2px solid gray;
}
.mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-color: black !important;
}
.mat-simple-snackbar .mdc-button__label {
    .mat-button-wrapper {
        color:#ff4081 !important;
    }
}
.mdc-data-table__row:last-child .mdc-data-table__cell {
    border-bottom-width: 1px !important;
    border-bottom-style: solid !important;
    border-bottom-color: #0000001f !important;
}
.mdc-icon-button svg,.mdc-icon-button img{
    width: 28px !important;
}
.mat_custom_header .mat-mdc-select-arrow-wrapper {
    padding-top: 0px !important;
}
::ng-deep .mat-mdc-select-arrow-wrapper {
    transform: translateY(0px) !important;
}
.mat-mdc-paginator .mat-mdc-select-value {
    font-size: 12px !important;
}
.mdc-linear-progress__bar-inner {
    border-color: $primary_blue !important;
}
.width_150 {
    width: 150px;
}

/* Custom Slide Toggle ----- STARTS */
.mat-mdc-slide-toggle.mat-primary {
    --mdc-switch-unselected-hover-track-color: #686767 !important;
    --mdc-switch-unselected-focus-track-color: #686767 !important;
    --mdc-switch-unselected-pressed-track-color: #686767 !important;

    .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
        background: $color_white !important;
    }

    .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
        background-color: $color_white !important;
    }
    .mdc-switch:enabled+.mdc-label {
        margin: 0 0 0 10px;
    }
}
.mdc-switch__icons {
    display: none;
}
.mdc-switch {
    width: 37px !important;

    .mdc-switch__track {
        height: 16px !important;
        width: 30px !important;
        border-radius: 36px !important;
    }

    .mdc-switch__shadow {
        display: none;
    }

    .mdc-switch__ripple {
        width: 20px !important;
        height: 20px !important;
    }

    .mdc-switch__ripple {
        width: 20px !important;
        height: 20px !important;
    }
}
.mdc-switch.mdc-switch .mdc-switch__handle {
    width: 12px !important;
    height: 12px !important;
}
.mdc-switch.mdc-switch--unselected .mdc-switch__handle {
    left: 1px !important;
}
/* Custom Slide Toggle ----- ENDS */


// The Below Classes are Used For Replace of FXLayout

// replace of fxLayout="row"  
.fx_row {
    display: flex;
    flex-direction: row;
}

//replace of fxLayout="column"
.fx_column {
    display: flex;
    flex-direction: column;
}

//replace of fxLayoutAlign="start" 
.fx_start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

// replace of fxLayoutAlign="start start" 
.fx_startstart {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

// replace of fxLayoutAlign="start center"
.fx_startcenter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

// replace of fxLayoutAlign="end"
.fx_end {
    display: flex;
    justify-content: flex-end;
}

// replace of fxLayoutAlign="end end" 
.fx_endend {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

//replace of fxLayoutAlign="end center" 
.fx_endcenter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

 // replace of fxLayoutAlign="center"
.fx_center {
    display: flex;
    justify-content: center;
}

// replace of fxLayoutAlign="center center" 
.fx_center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

// replace of fxLayoutAlign="center end"
.fx_centerend {
    display: flex;
    justify-content: flex-center;
    align-items: end;
}

// replace of fxLayoutAlign="space-between center" 
.fx_space-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
// replace of fxLayout ="row map"
.fx_row_map {
    display: flex;
    flex-wrap: wrap;
}
// replace of fxLayoutGap according to value

.fx_gap_2 {
    display: flex;
    gap: 2px;
}

.fx_gap_4 {
    display: flex;
    gap: 4px;
}

.fx_gap_5 {
    display: flex;
    gap: 5px;
}
.fx_gap_6 {
    display: flex;
    gap: 6px;
}
.fx_gap_8 {
    display: flex;
    gap: 8px;
}

.fx_gap_9 {
    display: flex;
    gap: 9px;
}

.fx_gap_10 {
    display: flex;
    gap: 10px;
}

.fx_gap_15 {
    display: flex;
    gap: 15px;
}

.fx_gap_20 {
    display: flex;
    gap: 20px;
}

.fx_gap_24 {
    display: flex;
    gap: 24px;
}

.fx_gap_25 {
    display: flex;
    gap: 25px;
}

.fx_gap_30 {
    display: flex;
    gap: 30px;
}

.fx_gap_40 {
    display: flex;
    gap: 40px;
}

.fx_gap_50 {
    display: flex;
    gap: 50px;
}

.fx_gap_70 {
    display: flex;
    gap: 70px;
}

// replace of  fxFlex according to value
.fx_1 {
    width: 1%;
}

.fx_2 {
    width: 2%;
}

.fx_3 {
    width: 3%;
}

.fx_4 {
    width: 4%;
}

.fx_5 {
    width: 5%;
}

.fx_6 {
    width: 6%;
}

.fx_7 {
    width: 7%;
}

.fx_8 {
    width: 8%;
}

.fx_10 {
    width: 10%;
}

.fx_11 {
    width: 11%;
}

.fx_12 {
    width: 12%;
}

.fx_13 {
    width: 13%;
}

.fx_14 {
    width: 14%;
}

.fx_15 {
    width: 15%;
}

.fx_16 {
    width: 16%;
}

.fx_18 {
    width: 18% !important;
} 

.fx_19 {
    width: 19%;
}

.fx_20 {
    width: 20%;
}

.fx_22 {
    width: 22%;
}
.fx_24{
    width: 24%;  
}

.fx_25 {
    width: 25%;
}

.fx_26 {
    width: 26%;
}

.fx_27 {
    width: 27%;
}

.fx_28 {
    width: 28%;
}

.fx_30 {
    width: 30%;
}

.fx_31 {
    width: 31%;
}

.fx_32 {
    width: 32%;
}

.fx_33 {
    width: 33%;
}

.fx_34 {
    width: 34%;
}

.fx_35 {
    width: 35%;
}

.fx_36 {
    width: 36%;
}

.fx_37 {
    width: 37%;
}

.fx_38 {
    width: 38%;
}

.fx_39 {
    width: 39%;
}

.fx_40 {
    width: 40%;
}

.fx_43 {
    width: 43%;
}

.fx_45 {
    width: 45%;
}
.fx_47 {
    width: 47%;
}

.fx_48 {
    width: 48%;
}

.fx_50 {
    width: 50%
}

.fx_52 {
    width: 52%
}

.fx_55 {
    width: 55%;
}

.fx_58 {
    width: 58%;
}

.fx_60 {
    width: 60%;
}

.fx_62 {
    width: 62%;
}

.fx_64 {
    width: 64%
}

.fx_65 {
    width: 65%;
}

.fx_68 {
    width: 68%;
}

.fx_70 {
    width: 70%;
}

.fx_73 {
    width: 73%;
}

.fx_75 {
    width: 75%;
}

.fx_80 {
    width: 80%
}
.fx_85 {
    width: 85%
}

.fx_90 {
    width: 90%
}

.fx_93 {
    width: 93%;
}

.fx_95 {
    width: 95%;
}

.fx_97 {
    width: 97%
}

.fx_98 {
    width: 98%
}

.fx_100 {
    width: 100%;
}

.fx_650px {
    width: 650px;
}

.fx_600px {
    width: 600px;
}
.fx_500px {
    width: 500px;
}
.fx_110{
    flex: 0 0 110px;
}

// alternative for fxFlex 
.flex {
    flex: 1 1 0%;
}

.fx_centerflexend{         
    display: flex;            
    justify-content: center;
    align-items: flex-end;
   }

//replace of fxFlex="10"
.fxFlex_10{
    flex: 0 0 10%; 
}

//replace of fxFlex="20"
.fxFlex_20{
    flex: 0 0 20%; 
}

//replace of fxFlex="70"
.fxFlex_70{
    flex: 0 0 70%; 
}
