@use 'sass:map';
@use '@angular/material' as mat;

@import './lib/styles/colors.scss';

// NOTE: Custom THEME for Synappx Cloud Print (SCP) app
.scp_theme {
  $dark-primary-text: rgba(black, 0.87);
  $dark-secondary-text: rgba(black, 0.54);
  $dark-disabled-text: rgba(black, 0.38);
  $dark-dividers: rgba(black, 0.12);
  $dark-focused: rgba(black, 0.12);

  $light-primary-text: white;
  $light-secondary-text: rgba(white, 0.7);
  $light-disabled-text: rgba(white, 0.5);
  $light-dividers: rgba(white, 0.12);
  $light-focused: rgba(white, 0.12);

  $custom-palette: (
    50: #e0f6f9,
    100: #b2e9f0,
    200: #80dbe7,
    300: #4fccdc,
    400: #2cc2d5,
    500: #14b7ce,
    600: #12a7bb,
    700: #1093a1,
    800: #0f7f8a,
    900: #0c5c5f,
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
    ),
  );

  $grey-palette: (
    50: #fafafa,
    100: #f5f5f5,
    200: #eeeeee,
    300: #e0e0e0,
    400: #bdbdbd,
    500: #9e9e9e,
    600: #757575,
    700: #616161,
    800: #424242,
    900: #212121,
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $dark-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
    ),
  );

  @include mat.core();

  $theme-primary: mat.define-palette($custom-palette, 600, 100, 800);
  $theme-accent: mat.define-palette($custom-palette, 600, 100, 800);
  $theme-warn: mat.define-palette(mat.$orange-palette);

  $theme: mat.define-light-theme(
    (
      color: (
        primary: $theme-primary,
        accent: $theme-accent,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    )
  );

  @include mat.all-component-themes($theme);

  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $hover-background-color: mat.get-color-from-palette($primary-palette, 700);

  // Custom button styles
  .mat-mdc-button,
  .mat-mdc-raised-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button {
    border-radius: 22px;
    font-size: 15px;
    background-color: mat.get-color-from-palette($grey-palette, 200);

    &:not(:disabled):hover {
      background-color: $hover-background-color;
      color: $light-primary-text;
    }
  }

  // Custom button-icon styles
  .mat-mdc-icon-button.large,
  .mat-mdc-icon-button.large .mat-icon {
    width: 40px !important;
    height: 40px !important;
    top: unset !important;

    font-size: 32px;
    line-height: 40px;

    &.mat-primary {
      &:not(:disabled):hover {
        color: $hover-background-color;
      }
    }
  }

  .mat-mdc-form-field-hint {
    color: $error_orange;
  }

  .mat-mdc-dialog-title {
    margin: 0;
  }

  .mat-mdc-dialog-content {
    &.mdc-dialog__content {
      padding: 20px !important;
      margin: 10px -20px;
    }
  }

  &.mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: #{$color_white};
    --mdc-snackbar-supporting-text-color: #{$color_black};

    &.error {
      --mdc-snackbar-supporting-text-color: #{$red_46};
    }
  }

  .mat-mdc-option {
    max-width: none;
  }

  .mat-column-select {
    width: 50px;
  }

  .mat-mdc-card-content {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .mat-mdc-tab {
    min-width: 160px;
  }

  .mat-headline-6 {
    font: 500 20px/32px Roboto, Helvetica Neue, sans-serif !important;
  }

  .mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-label-text-color: white;
  }

  .mat-mdc-unelevated-button.mat-unthemed {
    --mdc-filled-button-container-color: #eee;
  }

  .mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-checkmark-color: white;
  }

  .mat-mdc-header-cell {
    .mat-mdc-checkbox {
      --mdc-checkbox-unselected-focus-icon-color: white;
      --mdc-checkbox-unselected-hover-icon-color: white;
      --mdc-checkbox-unselected-icon-color: white;
      --mdc-checkbox-unselected-pressed-icon-color: white;
    }
  }

  .mat-mdc-header-cell:last-child {
    padding: 0 16px 0 16px !important;
  }

  .mat-mdc-cell:last-child {
    padding: 0 16px 0 16px !important;
  }

  .mat-mdc-card-title {
    font-size: 24px;
    font-weight: 500;
  }

  .mat-mdc-icon-button {
    --mat-mdc-button-persistent-ripple-color: transparent !important;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 32px;
    height: 32px;
  }

  // The Below Classes are Used For Replace of FXLayout
  // replace of fxLayout="row warp"
  .fx_rowwarp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  // replace of fxLayout="row nowarp"
  .fx_rownowarp {
    display: flex;
    flex-direction: row;
    flex-wrap: npwrap;
  }

  //replace of fxLayout="column warp"
  .fx_columnwarp {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  // replace of fxLayoutAlign="start stretch"
  .fx_startstretch {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  // replace of fxLayoutAlign="start end"
  .fx_startend {
    display: flex;
    justify-content: flex-start;
    align-items: end;
  }

  // replace of fxLayoutAlign="center stretch"
  .fx_centerstretch {
    display: flex;
    justify-content: flex-center;
    align-items: stretch;
  }

  // replace of fxLayoutAlign="space-between start"
  .fx_space-between-start {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  // replace of fxLayoutAlign="space-between baseline"
  .fx_space-between-baseline {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  // replace of fxLayoutAlign="space-between end"
  .fx_space-between-end {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }

  // replace of fxFlexFill
  .fx_flexfill {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
  }

  .flex_Card_2 {
    flex: 1 1 calc(300px - 8px);
  }

  .flex_Card_3 {
    flex: 1 1 calc(200px - 8px);
  }

  // replace of fxLayoutGap according to value
  .fx_gap_0 {
    display: flex;
    gap: 0px;
  }

  //replace of fxFlex="50"
  .fxFlex_50 {
    flex: 0 0 50%;
  }

  //replace of fxFlex="60"
  .fxFlex_60 {
    flex: 0 0 60%;
  }

  //replace of fxFlex="100"
  .fxFlex_100 {
    flex: 0 0 100%;
  }
}
