$primary_black: #303331;
$primary_blue: #12a7bb;
$hover_turquoise: #0b9093;
$hover_blue_calendar: #88d3dd; // added for calender hover and select
$error_orange: #e47e13;
$color_white: #ffffff;
$color_black: #000000;
$color_gray: #dcdfe1;
$secondary_black: #727672;
$gray_50: #808080;
$gray_54: #8A8A8A;
$gray_60: #999;
$gray_72: #b8b8b8;
$gray_74: #bdbdbd;
$gray_87: #ddd;
$gray_93: #EEEEEE;
$gray_98: #fafafa;
$blue_44: #1a7cc7;
$blue_45: #1979ca;
$blue_50: #0000ff;
$blue_82: #b7e4ea;
$cyan_85: #c4e9ee;
$green_69: #a9d18e;
$yellow_50: #ffc000;
$red_46: #e00c0c;
$color_disable: #00000061;
$color_label: #212529;
$color_activation: #fbe5d6;
$purple: #8DA0CB; //added for dashboard  charts
$light_blue: #8DD3C7; //added for dashboard  charts
$light_green: #66C2A5; //added for dashboard  charts
$salmon : #FC8D62; //added for dashboard  charts
$blue: #8EA1CC; //added for dashboard  charts
$light_gray: #C2C2C2; //added for dashboard  charts
$color_backgroud: #f7f7f7; // added for security sub heading
$mat-row-disabled: #e5e5e5; // added for to disable the row with light gray color to indicate licence is expiring
$mat-row-dark-disabled:#bbb7b7; // added for to disable the row with dark gray color to indicate licence is expiring
